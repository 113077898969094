import {
  resetAffectedField,
  useApiLeaseRelatedFields,
  useApiLeaseApproveAllRelatedMutation,
  useApiLeaseUnapproveAllRelatedMutation,
} from '@register'

export const useApproveAllRelated = (variationId: string) => {
  // GLOBAL
  const divisionId = getCurrentDivisionId()
  const leaseId = getCurrentLeaseId()

  // COMPUTED
  const { data } = useApiLeaseRelatedFields(divisionId, leaseId, variationId)
  const relatedVariations = computed(() => data.value ?? [])
  const relatedFieldIds = computed(() =>
    relatedVariations.value
      .map((variation) => variation.leaseField?.id)
      .filter(Boolean),
  )
  const isAllApproved = computed(() => {
    const approvedFields = relatedVariations.value.filter((variation) => {
      return !!variation.isApproved
    }).length
    const totalFields = relatedVariations.value.length
    return approvedFields / totalFields === 1
  })

  // SUBMIT
  const { mutateAsync: approveAll } = useApiLeaseApproveAllRelatedMutation(
    divisionId,
    leaseId,
    variationId,
  )
  const { mutateAsync: unapproveAll } = useApiLeaseUnapproveAllRelatedMutation(
    divisionId,
    leaseId,
    variationId,
  )

  const currentAction = ref<'approving' | 'unapproving'>()

  const submitHandler = useConfirmHandler(
    () => {
      if (isAllApproved.value) {
        currentAction.value = 'unapproving'
        return unapproveAll()
      }

      currentAction.value = 'approving'
      return approveAll()
    },
    {
      modal: {
        title: computed(() => {
          const action = isAllApproved.value ? 'unapprove' : 'approve'
          return `Are you sure you want ${action} all fields?`
        }),
        body: computed(() => {
          const opposite = isAllApproved.value ? 'approved' : 'unapproved'
          const action = isAllApproved.value ? 'unapproved' : 'approved'
          return `All your ${opposite} fields will be marked as ${action}.`
        }),
      },
      loadingMessage: computed(() => {
        const action = isAllApproved.value ? 'Unapproving' : 'Approving'
        return `${action} all fields...`
      }),
      successMessage: computed(() => {
        const action =
          currentAction.value === 'approving' ? 'approved' : 'unapproved'
        return `All fields have been ${action}.`
      }),
      errorMessage: computed(() => {
        const action =
          currentAction.value === 'approving' ? 'approve' : 'unapprove'
        return `Failed to ${action} all fields.`
      }),
      submitHandlerOptions: {
        onSuccess: () => {
          for (const fieldId of relatedFieldIds.value) {
            resetAffectedField(fieldId)
          }
        },
        onFinally: () => {
          currentAction.value = undefined
        },
      },
    },
  )

  return {
    isAllApproved,
    submitHandler,
  }
}
