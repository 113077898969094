<script setup lang="ts">
import { twMerge } from 'tailwind-merge'
import type { FormKitNode } from '@formkit/core'
import type {
  ILeaseVariationDocument,
  ILeaseVariation,
  IDocument,
} from '@register'
import { LeaseVariationKind, LeaseFieldType } from '@register'
import AmendmentEditFieldInput from './AmendmentEditFieldInput.vue'
import AmendmentEditFieldStatus from './AmendmentEditFieldStatus.vue'
import { useEditAmendmentForm } from './composables'

import { useAmendmentFormModal } from '../composables/useAmendmentFormModal'

// PROPS
const props = defineProps<{
  document: IDocument | undefined
  variation: ILeaseVariation
}>()

// GLOBAL
const divisionId = getCurrentDivisionId()
const leaseId = getCurrentLeaseId()

// FORM
const { form, formModelValue, isAllApproved, onSubmit } = useEditAmendmentForm(
  toRefs(props),
)

// RELATED FIELDS
const { data, isPending } = useApiLeaseRelatedFields(
  divisionId,
  leaseId,
  props.variation.id,
)
const relatedVariations = computed(() => data.value ?? [])

const dayjs = useDayjs()

const isClauseOrDefinitions = computed(() => {
  const fieldType = props.variation.leaseField?.fieldType
  return (
    fieldType === LeaseFieldType.CLAUSE ||
    fieldType === LeaseFieldType.DEFINITION
  )
})

const formClass = computed(() => {
  return twMerge(
    'flex-auto w-full grid gap-6',
    isClauseOrDefinitions.value ? 'grid-cols-1' : 'grid-cols-2',
  )
})

const amendment: ILeaseVariationDocument = {
  variations: [props.variation],
  document: props.document,
}
const onCreate = useAmendmentFormModal(
  amendment,
  formModelValue.value.startDate,
)
</script>

<template>
  <div class="mb-4 mt-2 rounded-lg">
    <AmendmentEditHeader
      :document="document"
      :variation="variation"
      @add="onCreate"
    />

    <FormKit
      ref="form"
      :value="formModelValue"
      type="form"
      form-class="p-4 mt-4"
      :actions="false"
      :disabled="isPending"
      @submit="onSubmit"
    >
      <div :class="formClass">
        <!-- Variation Effective Date -->
        <FormKit
          v-if="!isPending"
          v-model="formModelValue.startDate"
          type="datepicker"
          name="startDate"
          label="Effective date"
          min="1900-01-01"
          max="2100-12-31"
          validation="required|validDate"
          :validation-rules="{
            validDate: (node: FormKitNode) =>
              dayjs(node.value as any).isValid(),
          }"
          :validation-messages="{
            validDate: 'Invalid date',
          }"
          dirty-behavior="compare"
          outer-class=" formkit-disabled:!opacity-100"
          inner-class="formkit-disabled:ring-2 formkit-disabled:ring-primary formkit-disabled:bg-gray/30"
          input-class="formkit-disabled:!text-white"
          :disabled="isAllApproved"
          help="The date when the change will be effective"
        />

        <!-- NEW VALUE -->
        <template
          v-for="relatedVariation in relatedVariations"
          :key="relatedVariation.id"
        >
          <AmendmentEditFieldInput
            v-if="relatedVariation.leaseField"
            :variation="relatedVariation"
            :disabled="formModelValue.isApproved"
          />
          <AmendmentEditFieldStatus
            v-else-if="
              relatedVariation.variationKind ===
              LeaseVariationKind.STATUS_CHANGED
            "
            :variation="relatedVariation"
            :disabled="formModelValue.isApproved"
          >
          </AmendmentEditFieldStatus>
        </template>
      </div>
    </FormKit>

    <div class="flex justify-end gap-4 border-t border-gray-700 p-4">
      <Button
        color="secondary"
        class="border-gray-750 border"
        size="sm"
        :to="`/division/${divisionId}/lease/${leaseId}/amendments/`"
      >
        Back
      </Button>

      <Button
        v-if="!isPending"
        :color="isAllApproved ? 'primary' : 'transparentSecondary'"
        icon="check"
        class="border border-gray-700"
        ring
        ring-on-hover
        @click.prevent="form?.node.submit()"
      >
        {{ isAllApproved ? 'Unapprove' : 'Approve' }}
      </Button>
    </div>
  </div>
</template>
