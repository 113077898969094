import type { ToRefs } from 'vue'
import type { FormKitNode } from '@formkit/core'
import {
  type ILeaseVariation,
  type IDocument,
  useApiLeaseApproveAllRelatedMutation,
  useApiLeaseRelatedFields,
  useApproveAllRelated,
} from '@register'

import type { IUpdateLeaseVariationForm } from '../types'
import { AmendmentEditFormSymbol } from '../constants'

export const useEditAmendmentForm = (
  props: ToRefs<{
    document: IDocument | undefined
    variation: ILeaseVariation
  }>,
) => {
  // PROPS
  const { variation } = props

  // GLOBAL
  const divisionId = getCurrentDivisionId()
  const leaseId = getCurrentLeaseId()

  // FORM
  const form = ref<{ node: FormKitNode }>()
  const formModelValue = ref<IUpdateLeaseVariationForm>(
    initialValue() as IUpdateLeaseVariationForm,
  )
  provide(AmendmentEditFormSymbol, formModelValue)

  // SUBMIT
  const { data } = useApiLeaseRelatedFields(
    divisionId,
    leaseId,
    variation.value.id,
  )
  const { mutateAsync: approveAll } = useApiLeaseApproveAllRelatedMutation(
    divisionId,
    leaseId,
    variation.value.id,
  )
  const { submitHandler, isAllApproved } = useApproveAllRelated(
    variation.value.id,
  )

  return {
    form,
    formModelValue,
    isAllApproved,
    onSubmit: submitHandler,
  }

  function initialValue(): Partial<IUpdateLeaseVariationForm> {
    return {
      startDate: variation.value.startDate || undefined,
      isApproved: variation.value.isApproved,
    }
  }
}
