import { useMutation, useQueryClient } from '@tanstack/vue-query'
import type { ILeaseVariation } from '@register/types'

export const useApiLeaseUnapproveAllRelatedMutation = (
  divisionId: string,
  leaseId: string,
  variationId: string,
) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: () =>
      fetchAPI<ILeaseVariation[]>(
        `/companies/${divisionId}/leaseRegister/leases/${leaseId}/variations/${variationId}/related/unapproveAll`,
        {
          method: 'POST',
        },
      ),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['companies', divisionId, 'leases', leaseId],
      })
    },
  })
}
