import { useQuery } from '@tanstack/vue-query'
import type { ILeaseVariation } from '@register/types'

export const useApiLeaseRelatedFields = (
  divisionId: string,
  leaseId: string,
  variationId: string,
) => {
  return useQuery({
    queryKey: [
      'companies',
      divisionId,
      'leases',
      leaseId,
      'documents',
      'variations',
      variationId,
      'related',
    ],
    queryFn: ({ signal }) =>
      fetchAPI<ILeaseVariation[]>(
        `/companies/${divisionId}/leaseRegister/leases/${leaseId}/variations/${variationId}/related`,
        { signal },
      ),
  })
}
